import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik, Form } from 'formik'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import useAuthenticate from '@/hooks/useAuthenticate'
import LoginLayout from '@/components/hocs/LoginLayout'
import PasswordField from '@/components/Formik/PasswordField'
import { TermsAndConditionsPopup } from '@/components/Login/LoginPopups'

import * as SecurityApi from '@/api/security'
import {
  ROOT,
  EXPIRED_LINK,
  NEW_PASSWORD,
  PASSWORD_RESET_SUCCESS,
} from '@/routes'
import { AUTH_CONTEXT, PASSWORD_VALIDATIONS } from '@/constants'
import './NewPassword.scss'

const INIT_VALIDATIONS = PASSWORD_VALIDATIONS.reduce((a, c) => ({ ...a, [c]: false }), {})

const NewPassword = () => {
  const history = useHistory()
  const { code } = useParams()
  const { formatMessage } = useIntl()
  const { setLoading } = useLoader()
  const {
    authenticateWithCode,
    checkNext,
    tmpToken,
    nextActionPayload: { min_password_length: minPasswordLength },
  } = useSession()

  const authenticate = useAuthenticate()

  const validationActive = useRef(true)
  const [newPassword, setNewPassword] = useState('')
  const [popup, setPopup] = useState({ show: false })
  const [validations, setValidations] = useState(INIT_VALIDATIONS)
  const [validationLoading, setValidationLoading] = useState(false)
  const validationTimerRef = useRef()

  const token = useMemo(() => tmpToken || history.location.state?.token, [tmpToken, history.location.state])

  const lazyValidation = useCallback(values => {
    window.clearTimeout(validationTimerRef.current)

    validationTimerRef.current = window.setTimeout(async () => {
      try {
        const { data: { validations: data } } = await SecurityApi.validatePassword({
          auth_token: token,
          password: values.new_password,
          password_confirm: values.new_password_confirm,
        })

        if (validationActive.current) {
          setValidations(data)
          setNewPassword(values.new_password)
        }
      } catch {
        if (validationActive.current) setValidations(INIT_VALIDATIONS)
      }

      if (validationActive.current) setValidationLoading(false)
    }, 500)
  }, [token, setValidationLoading, setValidations, setNewPassword])

  const isDisabled = useMemo(() => {
    if (validationLoading) return true

    return !Object.values(validations).every(Boolean)
  }, [validationLoading, validations])

  const handleValidation = useCallback(values => {
    setValidationLoading(true)
    lazyValidation(values)
  }, [lazyValidation])

  const handleAccept = useCallback(async () => {
    setPopup({ show: false })
    try {
      setLoading(true)
      const { data } = await SecurityApi.setPassword({ ...popup.values, auth_token: token })
      if (history.location.state?.code || history.location.state?.token) {
        if (data.meta?.first_time) {
          await authenticate({ password: newPassword, username: data.email }, AUTH_CONTEXT)
          return
        }

        history.push(PASSWORD_RESET_SUCCESS)
      } else {
        await checkNext()
      }
    } catch (error) {
      // show something in case of set password error
      setLoading(false)
    }
  }, [history, popup, checkNext, token, setLoading, newPassword, authenticate])

  useEffect(() => {
    if (!history.location.state?.code && !code && !token) history.replace(ROOT)
  }, [code, history, token])

  useEffect(() => {
    if (code) history.replace({ pathname: NEW_PASSWORD, state: { code } })
  }, [code, history])

  useEffect(() => () => { validationActive.current = false }, [])

  useEffect(() => {
    if (history.location.state?.code) {
      (async () => {
        try {
          await authenticateWithCode(history.location.state?.code)
        } catch {
          history.replace(EXPIRED_LINK)
        }
      })()
    }
  }, [history, authenticateWithCode])

  return (
    <div className="content-wrapper new-password">
      <div className="login-titles">
        <div className="title">
          <FormattedMessage id="newPassword.create" />
        </div>
      </div>
      <Formik
        initialValues={{ new_password: '', new_password_confirm: '' }}
        validate={handleValidation}
        validateOnChange
        validateOnBlur={false}
        onSubmit={values => setPopup({ show: true, values })}
      >
        {() => (
          <>
            <div className="login-text-wrapper">
              <FormattedMessage id="newPassword.passwordMust" />
              <ul>
                {PASSWORD_VALIDATIONS.map(criteria => (
                  <li key={criteria} className={validations[criteria] ? 'valid' : 'incorrect'}>
                    <FormattedMessage id={`newPassword.${criteria}`} values={{ minPasswordLength }} />
                  </li>
                ))}
              </ul>
            </div>
            <Form className="login-form-wrapper">
              <ul className="login-field-wrapper">
                <li>
                  <PasswordField
                    name="new_password"
                    inputProps={{ placeholder: formatMessage({ id: 'placeholders.newPassword' }) }}
                  />
                </li>
                <li className="login-form-empty-space" />
                <li>
                  <PasswordField
                    name="new_password_confirm"
                    inputProps={{ placeholder: formatMessage({ id: 'placeholders.passwordConfirm' }) }}
                  />
                </li>
                <li className="login-form-empty-space" />
                <li>
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className={clsx('login-button', { blue: !isDisabled })}
                  >
                    <FormattedMessage id="newPassword.submit" />
                  </button>
                </li>
              </ul>
            </Form>
          </>
        )}
      </Formik>
      {popup.show && (
        <TermsAndConditionsPopup
          onAccept={handleAccept}
          onDecline={() => setPopup(false)}
        />
      )}
    </div>
  )
}

export default LoginLayout(NewPassword)
