import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import clsx from 'clsx'
import useCarousel from '@/hooks/useCarousel'
import { slidesJSONPath } from 'Config'

import Slide from '@/components/carousel/Slide'

import './carousel.scss'

const Carousel = () => {
  const isCancelled = useRef(false)
  const [slides, setSlides] = useState([])
  const [active, style] = useCarousel(slides.length, 8000)

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(slidesJSONPath)
        if (typeof data === 'object') {
          if (!isCancelled.current) setSlides(data)
        } else {
          // eslint-disable-next-line no-console
          console.error('Carousel configuration could not be read')
        }
      } catch (error) {
        if (!isCancelled.current) setSlides([])
      }
    })()
    return () => {
      isCancelled.current = true
    }
  }, [])

  return (
    <div className="carousel">
      <ol className="carousel-indicators">
        {slides.length > 1
          && slides.map((_, index) => <li key={index} className={clsx('indicator', active === index && 'active')} />)}
      </ol>
      {slides.length > 0 && (
        <div className="carousel-content" data-testid="carousel-content" style={style}>
          {slides.map(slide => <Slide key={slide.img_link} slide={slide} />)}
          {/* we need to duplicate first slide for correct CSS transition view */}
          <Slide slide={slides[0]} />
        </div>
      )}
    </div>
  )
}

export default Carousel
