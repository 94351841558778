import { useCallback, useState, useEffect } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { urls } from 'Config'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import LoginLayout from '@/components/hocs/LoginLayout'
import InputField from '@/components/Formik/InputField'
import { NeedToConfirmPopup } from '@/components/Login/LoginPopups'

import { CREATE_ACCOUNT_URL, EMAIL_REGEX } from '@/constants'

import { PASSWORD, NEW_PASSWORD } from '@/routes'
import './Login.scss'

const schema = Yup.object().shape({
  username: Yup.string().matches(EMAIL_REGEX).required(),
})

const Login = () => {
  const { setLoading } = useLoader()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { checkProvider, setUserEmail, uiTab } = useSession()
  const [error, setError] = useState(false)
  const [popup, setPopup] = useState(false)

  useEffect(() => history.listen((location, action) => {
    if (action === 'POP' && (location.pathname === NEW_PASSWORD)) {
      history.push('/')
    }
  }, [history]))

  const handleSubmit = useCallback(
    async values => {
      try {
        setLoading(true)
        const ssoDomain = await checkProvider(values)
        if (ssoDomain) {
          window.location.assign(`${urls.ssoApi}${ssoDomain}/${uiTab
            ? `?${encodeURIComponent(`returnurl=/dashboard.aspx#${uiTab}`)}`
            : ''
          }`)
          return
        }
        setUserEmail(values.username)
        history.push({ pathname: PASSWORD })
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    },
    [checkProvider, history, setLoading, setUserEmail, uiTab],
  )

  return (
    <div className="content-wrapper main-login">
      <Formik
        initialValues={{ username: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ dirty, isSubmitting }) => (
          <>
            <Form className="login-form-wrapper" autoComplete="off">
              <ul className="login-field-wrapper">
                {error && (
                  <li>
                    <ul className="error-section">
                      <li className="incorrect">{<FormattedMessage id="loginMain.checkID" />}</li>
                    </ul>
                  </li>
                )}
                <li>
                  <InputField
                    name="username"
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: formatMessage({ id: 'placeholders.emailOrId' }),
                      maxLength: 100,
                    }}
                    errorProps={{ message: formatMessage({ id: 'loginMain.invalidUsername' }) }}
                  />
                </li>
                <li className="actions">
                  <a onClick={() => setPopup(true)} className="login-blue-link">
                    {<FormattedMessage id="loginMain.forgotEmail" />}
                  </a>
                  <button
                    disabled={!dirty || isSubmitting}
                    type="submit"
                    className={clsx('login-button', { blue: dirty && !isSubmitting })}
                  >
                    {<FormattedMessage id="buttons.next" />}
                  </button>
                </li>
              </ul>
            </Form>
            <div className="no-account">
              <a
                href={CREATE_ACCOUNT_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage({ id: 'loginMain.noAccount' })}
              </a>
            </div>
          </>
        )}
      </Formik>
      {popup && <NeedToConfirmPopup onClose={() => setPopup(false)} />}
    </div>
  )
}

export default LoginLayout(Login)
