import { useReducer, useEffect } from 'react'

const transitionTime = 400
const smooth = `transform ${transitionTime}ms ease`

const initialCarouselState = {
  desired: 0,
  active: 0,
}

function carouselReducer(state, action) {
  switch (action.type) {
    case 'next':
      return {
        ...state,
        desired: (state.active + 1) % action.length,
      }
    case 'done':
      return {
        ...state,
        active: state.desired,
      }
    default:
      return state
  }
}

/**
 * This React Hook implements dynamic styles for slides carousel
 *
 * Note that for correct functionality you need to add first slide twice
 * duplicated first slide should be placed after all other slides
 * this required for correct CSS transition
 *
 * @param {integer} length length of slides array
 * @param {integer} interval interval before switch to next slide
 * @returns {[integer, object]} active slide, main container styles
 */
export default function useCarousel(length, interval = 5000) {
  const [{ active, desired }, dispatch] = useReducer(carouselReducer, initialCarouselState)

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: 'next', length }), interval)
    return () => clearTimeout(id)
  }, [active, length, interval])

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: 'done' }), transitionTime)
    return () => clearTimeout(id)
  }, [desired])

  const style = {
    transform: 'translateX(0)',
    width: `${100 * (length + 1)}%`,
    left: `-${active * 100}%`,
  }

  if (desired !== active) {
    const shift = -100 / (length + 1)
    style.transition = smooth
    style.transform = `translateX(${shift}%)`
  }

  return [active, style]
}
