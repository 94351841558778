import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import LoginLayout from '@/components/hocs/LoginLayout'
import BackToLogin from '@/components/Login/BackToLogin'

import * as SecurityApi from '@/api/security'
import { TWO_FA, EMAIL_SENT } from '@/routes'

import './RecoverPassword.scss'

const PasswordRecover = () => {
  const { push } = useHistory()
  const { setLoading } = useLoader()
  const { userEmail } = useSession()

  const handleCodeReset = useCallback(async () => {
    try {
      setLoading(true)
      await SecurityApi.resetPasswordWith2FA({ email: userEmail })
      push({ pathname: TWO_FA, state: { pwdReset: true } })
    } finally {
      setLoading(false)
    }
  }, [push, setLoading, userEmail])

  const handleEmailReset = useCallback(async () => {
    try {
      setLoading(true)
      await SecurityApi.resetPassword(userEmail)
      push(EMAIL_SENT)
    } finally {
      setLoading(false)
    }
  }, [push, setLoading, userEmail])

  return (
    <div className="content-wrapper recover-password">
      <div className="login-titles">
        <div className="title">
          <FormattedMessage id="recoverPassword.title" />
        </div>
        <div className="sub-title">
          <FormattedMessage id="recoverPassword.subTitle" />
        </div>
      </div>
      <form className="login-form-wrapper">
        <ul className="login-field-wrapper">
          <li>
            <button type="button" className="login-button blue" onClick={handleCodeReset}>
              <FormattedMessage id="recoverPassword.sendCode" />
            </button>
          </li>
          <li className="login-form-empty-space" />
          <li>
            <button type="button" className="login-button blue" onClick={handleEmailReset}>
              <FormattedMessage id="recoverPassword.sendLink" />
            </button>
          </li>
        </ul>
      </form>
      <div className="login-buttons-wrapper">
        <BackToLogin />
      </div>
    </div>
  )
}

export default LoginLayout(PasswordRecover)
