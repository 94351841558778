/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Вернуться на страницу авторизации',
    change: 'Изменить',
    finish: 'Завершить',
    next: 'Далее',
    ok: 'Ok',
    submit: 'Подтвердить',
  },
  carousel: {
    announcementLink: 'Нажмите здесь для дополнительной информации',
    announcementText: 'Здравствуйте, мы ввели новые функциональные средства',
  },
  emailSent: {
    checkEmail: 'Мы отправили на этот адрес электронное письмо с инструкциями по сбросу. Если вы не увидите его через минуту, проверьте папку со спамом или убедитесь, что указанный выше адрес электронной почты правильный.',
    resentLink: 'Отправить ссылку повторно',
    subTitle: 'Проверьте папку входящие',
  },
  expiredLink: {
    sentNewLink: 'Похоже, срок действия ссылки истек. Мы отправили новую ссылку на ваш адрес электронной почты.',
    title: 'Ой!',
  },
  lockedUser: {
    title: 'Статус вашей учетной записи отключен, заблокирован или не приглашен. Обратитесь к администратору ZenQMS за помощью в доступе к вашей учетной записи.',
  },
  loginMain: {
    checkID: 'Вы ввели неверный идентификатор пользователя/пароль.',
    forgotEmail: 'Не помните адрес электронной почты или идентификатор?',
    forgotPassword: 'Забыли ваш пароль?',
    invalidUsername: 'Введенное вами имя пользователя недействительно.',
    login: 'Авторизоваться',
    noAccount: 'Нет учетной записи?',
  },
  needToConfirmPopup: {
    header: 'Обратитесь к администратору компании ZenQMS за помощью к верификации вашей учетной записи.',
  },
  newPassword: {
    containsNumber: '- Должно быть не менее 1 цифры',
    create: 'Создать новый пароль',
    expiredPasswordAlert: 'Срок действия вашего пароля истек, и его необходимо сбросить.',
    hasNoRepeatCharacters: '- Не включать более 3 повторяющихся символов',
    isLengthValid: '- Должно быть не менее {minPasswordLength}символов',
    isMatch: '- Пароли совпадают',
    notEmail: '- Не должен быть таким же как ваша электронная почта',
    notUsed: '- Не повторять один из последних 12 паролей',
    passwordMust: 'Ваш пароль должен:',
    submit: 'Подтвердить',
  },
  passwordResetSuccess: {
    subTitle: 'Нажмите «Назад к авторизации» ниже, чтобы войти в систему с новым паролем.',
    title: 'Ваш пароль был сброшен!',
  },
  placeholders: {
    emailOrId: 'Электронная почта или идентификатор пользователя',
    newPassword: 'Новый пароль',
    password: 'Пароль',
    passwordConfirm: 'Подтвердить новый пароль',
  },
  recoverPassword: {
    sendCode: 'Отправить код на мое устройство',
    sendLink: 'Отправить ссылку на мою электронную почту',
    subTitle: 'Как бы вы хотели восстановить свой пароль?',
    title: 'Восстановление пароля',
  },
  resetPassword: {
    email: 'Электронное письмо',
    sendLink: 'Отправить ссылку',
    subTitle: 'Требуется проверка',
  },
  setTwoFa: {
    code: 'Мы отправим вам код безопасности на этот номер всякий раз, когда вы войдете в систему с ненадежного устройства.',
    subTitle: 'Введите номер вашего мобильного телефона',
    title: 'Настройка двухуровневой авторизации',
    locked: 'Это неверно. Ваша учетная запись заблокирована в целях безопасности.  Попросите администратора сбросить настройки вашей учетной записи или отправьте электронное письмо help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Принять',
    and: ' и ',
    decline: 'Отклонить',
    header: 'Положения и условия/ политика конфиденциальности',
    message1: 'Я просмотрел(а) и согласен(-а) соблюдать последнюю версию ZenQMS ',
    message2: ', включая согласие на использование временных сеансовых файлов cookie, необходимых для обработки данных и хранения информации на наших защищенных серверах по всему миру.',
    privacy: 'Политика конфиденциальности',
    terms: 'Условия использования',
  },
  twofa: {
    code: 'Код безопасности',
    noCode: 'Не получили код?',
    rememberDevice: 'Запомните это устройство в течение 30 дней',
    resend: 'Отправить повторно',
    subTitle: 'Ваша учетная запись защищена двухфакторной аутентификацией (2FA)',
    title: 'Проверка идентификации',
    verification: 'Текстовое сообщение с вашим кодом было отправлено на (***) ***-{number}',
    verify: 'Подтвердить',
    wrongCode: 'Вы ввели неверный код, попробуйте еще раз',
    verificationLimits: 'Вы превысили лимит проверки, повторите попытку через 10 минут',
  },
  updateEmail: {
    alreadyExist: 'Этот адрес электронной почты уже используется',
    code: 'Мы отправим вам код безопасности на этот номер всякий раз, когда вы войдете в систему с ненадежного устройства.',
    title: 'Ваш адрес электронной почты был ограничен вашими настройками администрирования. Пожалуйста, введите новый адрес электронной почты для вашей учетной записи.',
    success: 'Ваш адрес электронной почты обновлен. Пожалуйста, нажмите «Назад к авторизации», чтобы войти в приложение.',
    wrongDomain: 'этот домен электронной почты не разрешен',
  },
  alert: {
    error: '[TRANSLATE]Error',
    success: '[TRANSLATE]Success',
    warning: 'Предупреждение',
  },
  memberLocked: {
    title: 'Аккаунт вашей компании заблокирован. Обратитесь к администратору ZenQMS или по адресу billing@zenqms.com.',
  },
  validations: {
    email: 'Электронная почта является обязательным полем',
  },
}
