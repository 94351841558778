import { FormattedMessage } from 'react-intl'

import LoginLayout from '@/components/hocs/LoginLayout'

import './ExpiredLink.scss'

const ExpiredLink = () => (
  <div className="content-wrapper">
    <div className="login-text-wrapper expired-link">
      <p>
        <FormattedMessage id="expiredLink.title" />
      </p>
      <br />
      <p>
        <FormattedMessage id="expiredLink.sentNewLink" />
      </p>
    </div>
    <div className="login-buttons-wrapper"></div>
  </div>
)

export default LoginLayout(ExpiredLink)
