import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { Formik, Form } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import LoginLayout from '@/components/hocs/LoginLayout'
import InputField from '@/components/Formik/InputField'
import BackToLogin from '@/components/Login/BackToLogin'

import toast from '@/utils/toast'
import * as SecurityApi from '@/api/security'

import './UpdateEmail.scss'

const schema = Yup.object().shape({
  email: Yup.string().required({ id: 'validations.email' }),
})

const UpdateEmail = () => {
  const { formatMessage } = useIntl()
  const { setLoading } = useLoader()
  const { tmpToken } = useSession()

  const [updateStatus, setUpdateStatus] = useState(false)

  const handleSubmit = useCallback(async values => {
    try {
      setLoading(true)
      await SecurityApi.updateEmail({ ...values, auth_token: tmpToken })
      setUpdateStatus(true)
      setLoading(false)
    } catch (err) {
      if (err.response?.data?.validations?.isEmailAvailable === false) {
        toast.error(formatMessage({ id: 'updateEmail.alreadyExist' }))
      }
      if (err.response.data.name === 'ValidationError' || err.response?.data?.validations?.isDomainValid === false) {
        toast.error(formatMessage({ id: 'updateEmail.wrongDomain' }))
      }
      setLoading(false)
    }
  }, [setLoading, tmpToken, formatMessage])

  return (
    <div className="content-wrapper update-email">
      {updateStatus ? (
        <div className="title">
          <FormattedMessage id="updateEmail.success" />
        </div>
      ) : (
        <>
          <div className="title">
            <FormattedMessage id="updateEmail.title" />
          </div>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({ isValid, dirty, isSubmitting }) => (
              <Form className="login-form-wrapper" autoComplete="off">
                <ul className="login-field-wrapper">
                  <li>
                    <InputField
                      name="email"
                      inputProps={{ autoComplete: 'off', placeholder: formatMessage({ id: 'placeholders.emailOrId' }) }}
                    />
                  </li>
                  <li className="actions">
                    <button
                      disabled={!dirty || !isValid || isSubmitting}
                      type="submit"
                      className={clsx('login-button', { blue: isValid && dirty && !isSubmitting })}
                    >
                      {<FormattedMessage id="buttons.submit" />}
                    </button>
                  </li>
                </ul>
              </Form>
            )}
          </Formik>
        </>
      )}
      <div className="login-buttons-wrapper">
        <BackToLogin />
      </div>
    </div>
  )
}

export default LoginLayout(UpdateEmail)
