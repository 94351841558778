import PropTypes from 'prop-types'

const Slide = ({
  slide: {
    announcement_link: announcementLink,
    announcement_text: announcementText,
    img_link: imgLink,
    type,
  },
}) => (
  <div style={{ backgroundImage: `url(${imgLink}` }} className="carousel-item">
    {type === 'announcement' && (
      <div className="announcement">
        <div className="announcement-text">{announcementText}</div>
        <div className="announcement-link">
          <a href={announcementLink.url} target="_blank" rel="noopener noreferrer">
            {announcementLink.text}
          </a>
        </div>
      </div>
    )}
  </div>
)

Slide.propTypes = {
  slide: PropTypes.shape({
    announcement_link: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    announcement_text: PropTypes.string,
    img_link: PropTypes.string,
    type: PropTypes.oneOf(['announcement', 'image']),
  }).isRequired,
}

export default Slide
