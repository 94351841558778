import { hot } from 'react-hot-loader/root'
import PropTypes from 'prop-types'
import { HashRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { toast, Toaster, ToastBar } from 'react-hot-toast'

import LoaderProvider from '@/providers/loader/LoaderProvider'
import SessionProvider from '@/providers/session/SessionProvider'
import Router from '@/components/Router'
import BrowserValidation from '@/components/BrowserValidation/BrowserValidation'

import getMessages from '@/translations'
import flattenMessages from '@/utils/flattenMessages'

import '../styles/main.scss'
import '../styles/toaster.scss'

const App = ({ locale = 'en-US' }) => (
  <IntlProvider locale={locale} messages={flattenMessages(getMessages(locale))}>
    <BrowserValidation />
    <Toaster toastOptions={{ duration: 5000 }}>
      {t => (
        <ToastBar toast={t}>
          {({ message }) => (
            <>
              {message}
              {t.type !== 'loading' && (
                <i onClick={() => toast.dismiss(t.id)} />
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>

    <SessionProvider>
      <LoaderProvider>
        <HashRouter>
          <Router />
        </HashRouter>
      </LoaderProvider>
    </SessionProvider>
  </IntlProvider>
)

App.propTypes = {
  locale: PropTypes.string,
}

export default hot(App)
