import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSession } from '@/providers/session/SessionProvider'
import { TWO_FA, SET_2FA } from '@/routes'
import { AUTH_CONTEXT } from '@/constants'

/**
 * This React Hook implements shared authentication flow
 *
 * It authenticates the user with the given credentials and perform the neccesary redirects
 *
 * @returns {function} Authenticate the user based on given credentials
 */
export default function useAuthenticate() {
  const { push } = useHistory()
  const { authenticate, checkNext } = useSession()

  return useCallback(async (values, context) => {
    const {
      jwtAuthToken: authToken,
      require_2fa: secondFactor,
      partial_two_fa_phone: twoFaPhone,
    } = await authenticate(values)

    if (secondFactor) {
      if (context === AUTH_CONTEXT) {
        push({ pathname: SET_2FA })
      } else {
        push({ pathname: TWO_FA, state: { token: authToken, twoFaPhone } })
      }

      return
    }

    await checkNext()
  }, [push, checkNext, authenticate])
}
