import Bowser from 'bowser'
import { SUPPORTED_BROWSERS, SUPPORTED_BROWSER_VERSIONS } from '@/constants'
import './BrowserValidation.scss'

const browser = Bowser.getParser(window.navigator.userAgent)
const isValidBrowser = SUPPORTED_BROWSERS.includes(browser.getBrowserName())
const isValidBrowserVersion = browser.satisfies(SUPPORTED_BROWSER_VERSIONS)

const BrowserValidation = () => {
  if (!isValidBrowser) {
    return (
      <div className="outdated-browser-message">
        ZenQMS does not support your browser, and the application may not work properly.
        Please try the latest version of Chrome, Firefox, Safari, or Edge (Chromium) for best results.
      </div>
    )
  }

  if (!isValidBrowserVersion) {
    return (
      <div className="outdated-browser-message">
        You browser appears to be out of date. Please update to the latest version for best results.
      </div>
    )
  }

  return null
}

export default BrowserValidation
