/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Volver a Inicio de sesión',
    change: 'Cambiar',
    finish: 'Acabar',
    next: 'Siguiente',
    ok: 'OK',
    submit: 'Enviar',
  },
  carousel: {
    announcementLink: 'Haga clic aquí para obtener más información',
    announcementText: 'Hola, hemos introducido algunas funciones nuevas',
  },
  emailSent: {
    checkEmail: 'Hemos enviado un correo electrónico a esta dirección con instrucciones de restablecimiento. Si no lo ve en un minuto, compruebe su carpeta de correo no deseado o verifique que la dirección de correo electrónico anterior es correcta.',
    resentLink: 'Reenviar enlace',
    subTitle: 'Compruebe su bandeja de entrada',
  },
  expiredLink: {
    sentNewLink: 'Parece que el enlace ha caducado. Hemos enviado una nueva a su dirección de correo electrónico.',
    title: '¡Vaya!',
  },
  lockedUser: {
    title: 'El estado de su cuenta es Deshabilitado, Bloqueado o Sin invitación. Póngase en contacto con su administrador de ZenQMS para obtener ayuda para acceder a su cuenta.',
  },
  loginMain: {
    checkID: 'El ID de usuario/contraseña que ha introducido es incorrecto',
    forgotEmail: '¿No recuerda el correo electrónico o el ID?',
    forgotPassword: '¿Ha olvidado su contraseña?',
    invalidUsername: 'El nombre de usuario que ha introducido no es válido.',
    login: 'Iniciar sesión',
    noAccount: '¿No tiene una cuenta?',
  },
  needToConfirmPopup: {
    header: 'Póngase en contacto con el administrador de ZenQMS de su empresa para obtener ayuda para verificar su ID de cuenta.',
  },
  newPassword: {
    containsNumber: '- Tener al menos 1 número',
    create: 'Crear una nueva contraseña',
    expiredPasswordAlert: 'Su contraseña ha caducado y debe restablecerse.',
    hasNoRepeatCharacters: '- No incluir más de 3 caracteres repetidos',
    isLengthValid: '- Tener al menos {minPasswordLength} caracteres',
    isMatch: '- Las contraseñas coinciden',
    notEmail: '- No ser igual que su correo electrónico',
    notUsed: '- No repita una de sus últimas 12 contraseñas',
    passwordMust: 'Su contraseña debe:',
    submit: 'Enviar',
  },
  passwordResetSuccess: {
    subTitle: 'Haga clic en “Volver al inicio de sesión” a continuación para iniciar sesión con su nueva contraseña.',
    title: 'Su contraseña se ha restablecido.',
  },
  placeholders: {
    emailOrId: 'Correo electrónico o ID de usuario',
    newPassword: 'Nueva contraseña',
    password: 'Contraseña',
    passwordConfirm: 'Confirmar nueva contraseña',
  },
  recoverPassword: {
    sendCode: 'Enviar código a mi dispositivo',
    sendLink: 'Enviar enlace a mi correo electrónico',
    subTitle: '¿Cómo desea recuperar su contraseña?',
    title: 'Recuperación de contraseña',
  },
  resetPassword: {
    email: 'Correo electrónico',
    sendLink: 'Enviar enlace',
    subTitle: 'Se requiere verificación',
  },
  setTwoFa: {
    code: 'Le enviaremos un código de seguridad a este número siempre que inicie sesión desde un dispositivo no fiable.',
    subTitle: 'Introduzca su número de teléfono móvil',
    title: 'Configuración de autorización de dos factores',
    locked: 'Eso no es correcto. Su cuenta se ha bloqueado como medida de seguridad.  Solicite a su administrador que restablezca su cuenta o envíe un correo electrónico a help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Aceptar',
    and: ' y ',
    decline: 'Rechazar',
    header: 'Términos y condiciones/Política de privacidad',
    message1: 'He revisado y acepto acatar la última versión de ZenQMS ',
    message2: ', incluido el consentimiento para las cookies de sesión temporales necesarias para el procesamiento de datos y el almacenamiento de información en nuestros servidores seguros de todo el mundo.',
    privacy: 'Política de privacidad',
    terms: 'Términos de servicio',
  },
  twofa: {
    code: 'Código de seguridad',
    noCode: '¿No ha recibido un código?',
    rememberDevice: 'Recordar este dispositivo durante 30 días',
    resend: 'Reenviar',
    subTitle: 'Su cuenta está protegida con Two-Factor Authentication (2FA)',
    title: 'Verificación de seguridad',
    verification: 'Se ha enviado un mensaje de texto con su código a (***) ***-{number}',
    verify: 'Verificar',
    wrongCode: 'El código que ha introducido es incorrecto, inténtelo de nuevo',
    verificationLimits: 'Ha excedido el límite de verificación, inténtelo de nuevo en 10 minutos',
  },
  updateEmail: {
    alreadyExist: 'Este correo electrónico ya se está utilizando',
    code: 'Le enviaremos un código de seguridad a este número siempre que inicie sesión desde un dispositivo no fiable.',
    title: 'Su correo electrónico ha sido restringido por su configuración de administración. Introduzca una nueva dirección de correo electrónico para su cuenta.',
    success: 'Su correo electrónico se ha actualizado. Haga clic en “Volver al inicio de sesión” para iniciar sesión en la aplicación.',
    wrongDomain: 'este dominio de correo electrónico no está \'permitido',
  },
  alert: {
    error: '[TRANSLATE]Error',
    success: '[TRANSLATE]Success',
    warning: 'Advertencia',
  },
  memberLocked: {
    title: 'La cuenta de su empresa se ha bloqueado. Póngase en contacto con su administrador de ZenQMS o billing@zenqms.com',
  },
  validations: {
    email: 'El campo Correo electrónico es obligatorio',
  },
}
