import { useCallback } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { FormattedMessage } from 'react-intl'
import * as LoginApi from '@/api/login'
import * as Yup from 'yup'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import PhoneNumberField from '@/components/Formik/PhoneNumberField'
import LoginLayout from '@/components/hocs/LoginLayout'

import { TWO_FA } from '@/routes'

import './TwoFA.scss'

const schema = Yup.object().shape({
  phone: Yup.string().required(),
})

const SetTwoFA = () => {
  const { setLoading } = useLoader()
  const { push } = useHistory()
  const { tmpToken } = useSession()

  const handleSubmit = useCallback(async ({ phone }) => {
    try {
      const phoneNumber = phone.replace(/[^+|0-9]+/g, '')
      const twoFaPhone = phoneNumber.slice(-4)
      setLoading(true)
      await LoginApi.register2FAPhone({ phone: phoneNumber, auth_token: tmpToken })

      push({ pathname: TWO_FA, state: { token: tmpToken, register: true, twoFaPhone } })
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [setLoading, tmpToken, push])

  return (
    <div className="content-wrapper set-two-fa">
      <div className="login-titles">
        <div className="title">
          <FormattedMessage id="setTwoFa.title" />
        </div>
        <div className="sub-title">
          <FormattedMessage id="setTwoFa.subTitle" />
        </div>
      </div>
      <div className="login-text-wrapper">
        <FormattedMessage id="setTwoFa.code" />
      </div>
      <Formik
        initialValues={{ phone: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form className="2fa-form-wrapper">
            <PhoneNumberField name="phone" />
            <div className="login-buttons-wrapper">
              <button
                disabled={!dirty || !isValid || isSubmitting}
                className={clsx('login-button', { blue: isValid && dirty && !isSubmitting })}
                type="submit"
              >
                <FormattedMessage id="buttons.finish" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginLayout(SetTwoFA)
