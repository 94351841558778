import { FormattedMessage } from 'react-intl'
import { reCaptchaSiteKey } from 'Config'

import { useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import LoginLayout from '@/components/hocs/LoginLayout'
import BackToLogin from '@/components/Login/BackToLogin'

import { RECOVER_PASSWORD } from '@/routes'

import './ResetPassword.scss'

const ResetPassword = () => {
  const history = useHistory()

  const onChange = value => {
    if (value.length) history.push(RECOVER_PASSWORD)
  }

  return (
    <div className="content-wrapper reset-password">
      <div className="login-titles">
        <div className="sub-title">
          <FormattedMessage id="resetPassword.subTitle" />
        </div>
      </div>
      <div className="login-form-wrapper">
        <ul className="login-field-wrapper">
          <li>
            <ReCAPTCHA
              sitekey={reCaptchaSiteKey}
              onChange={onChange}
            />
          </li>
          <li className="login-form-empty-space" />
        </ul>
      </div>
      <div className="login-buttons-wrapper">
        <BackToLogin />
      </div>
    </div>
  )
}

export default LoginLayout(ResetPassword)
