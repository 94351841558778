/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: '返回登录',
    change: '变更',
    finish: '完成',
    next: '下一步',
    ok: '好',
    submit: '提交',
  },
  carousel: {
    announcementLink: '点击此处了解更多信息',
    announcementText: '大家好，我们引入了一些新功能',
  },
  emailSent: {
    checkEmail: '我们向该地址发送了一封包含重置说明的电子邮件。如果您在一分钟内没有看到它，请检查您的垃圾邮件文件夹或核实上述电子邮件地址是正确的。',
    resentLink: '重新发送链接',
    subTitle: '检查您的收件箱',
  },
  expiredLink: {
    sentNewLink: '链接似乎已过期。我们已向您的电子邮件地址发送了一份新邮件。',
    title: '哎呀!',
  },
  lockedUser: {
    title: '您的帐户状态要么是“已禁用”、要么是“已锁住”或“未被邀请”。请联系您的 ZenQMS 管理员，以获得访问帐户的帮助。',
  },
  loginMain: {
    checkID: '您输入的用户 ID/密码不正确',
    forgotEmail: '不记得电子邮件地址或 ID？',
    forgotPassword: '忘记了您的密码？',
    invalidUsername: '您输入的用户名无效。',
    login: '登录',
    noAccount: '没有账户？',
  },
  needToConfirmPopup: {
    header: '请联系您公司的 ZenQMS 管理员获取验证帐户 ID 的帮助。',
  },
  newPassword: {
    containsNumber: '- 至少有 1 个数字',
    create: '创建新密码',
    expiredPasswordAlert: '您的密码已过期，必须重置。',
    hasNoRepeatCharacters: '- 不包括 3 个以上重复字符',
    isLengthValid: '- 至少包含 {minPasswordLength} 个字符',
    isMatch: '- 密码匹配',
    notEmail: '- 与电子邮件不同',
    notUsed: '- 不与您最近 12 个密码中的一个重复',
    passwordMust: '您的密码必须：',
    submit: '提交',
  },
  passwordResetSuccess: {
    subTitle: '单击下面的“返回登录”，使用新密码登录。',
    title: '您的密码已重置！',
  },
  placeholders: {
    emailOrId: '电子邮件或用户 ID',
    newPassword: '新密码',
    password: '密码',
    passwordConfirm: '确认新密码',
  },
  recoverPassword: {
    sendCode: '将代码发送到我的设备',
    sendLink: '将链接发送至我的电子邮件',
    subTitle: '您希望如何恢复您的密码？',
    title: '密码恢复',
  },
  resetPassword: {
    email: '电子邮件',
    sendLink: '发送链接',
    subTitle: '需要验证',
  },
  setTwoFa: {
    code: '当您从不受信任的设备登录时，我们会将安全码发送到此号码。',
    subTitle: '输入您的手机号码',
    title: '双重身份验证设置',
    locked: '不正确。为安全起见，您的帐户已被锁住。请让您的管理员重置您的帐户，或发送电子邮件至 help@zenqms.com 。',
  },
  termsAndConditionsPopup: {
    accept: '接受',
    and: ' 和 ',
    decline: '拒绝',
    header: '条款和条件/隐私政策',
    message1: '我已审核并同意遵守最新的 ZenQMS ',
    message2: '，包括同意在我们全球的安全服务器中处理和存储信息所需的临时会话 cookie。',
    privacy: '隐私政策',
    terms: '服务条款',
  },
  twofa: {
    code: '安全码',
    noCode: '没有收到代码？',
    rememberDevice: '记住此设备 30 天',
    resend: '重新发送',
    subTitle: '您的帐户受双重身份验证 （ 2FA ） 保护',
    title: '安全验证',
    verification: '包含您代码的短信已发送至 （***） ***-{number}',
    verify: '验证',
    wrongCode: '您输入的代码不正确，请重试',
    verificationLimits: '您已超过验证限制，请在 10 分钟后重试',
  },
  updateEmail: {
    alreadyExist: '此电子邮件已被使用',
    code: '当您从不受信任的设备登录时，我们会将安全码发送到此号码。',
    title: '您的电子邮件已被您的管理设置限制。请为您的帐户输入一个新电子邮件地址。',
    success: '您的电子邮件已更新。请单击“返回登录”以登录应用程序。',
    wrongDomain: '不允许此电子邮件域\'',
  },
  alert: {
    error: '[TRANSLATE]Error',
    success: '[TRANSLATE]Success',
    warning: '警告',
  },
  memberLocked: {
    title: '贵公司的帐户已被锁住。请联系您的 ZenQMS 管理员或 billing@zenqms.com',
  },
  validations: {
    email: '“电子邮件”为必填字段',
  },
}
