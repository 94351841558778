import { Link, useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import Carousel from '@/components/carousel'
import Footer from '@/components/Login/Footer'
import Spinner from '@/components/Spinner/Spinner'

import { ROOT } from '@/routes'
import toast from '@/utils/toast'
import { NEXT_ACTION_PATHNAMES, UPDATE_PASSWORD_ACTION } from '@/constants'

import './LoginLayout.scss'
import './LoginLogo.scss'
import './LoginContent.scss'
import { useEffect } from 'react'

const LoginLayout = Component => {
  const WrappedComponent = props => {
    const { formatMessage } = useIntl()
    const { push } = useHistory()
    const { pathname } = useLocation()
    const { loading, setLoading } = useLoader()
    const { nextAction, nextActionPayload } = useSession()

    useEffect(() => { setLoading(false) }, [setLoading])

    useEffect(() => {
      if ((NEXT_ACTION_PATHNAMES[nextAction] && !NEXT_ACTION_PATHNAMES[nextAction].includes(pathname))) {
        if (nextAction === UPDATE_PASSWORD_ACTION && nextActionPayload.is_password_expired) {
          toast.warning(formatMessage({ id: 'newPassword.expiredPasswordAlert' }))
        }
        push(NEXT_ACTION_PATHNAMES[nextAction][0])
      }
    }, [nextAction, pathname, push, nextActionPayload.is_password_expired, formatMessage])

    return (
      <div className="login-page">
        <div className="login-side">
          <div className="logo-wrapper">
            <Link to={ROOT} />
          </div>
          <Component {...props} />
          <Footer />
          {loading && <Spinner />}
        </div>
        <div className="login-carousel">
          <Carousel />
        </div>
      </div>
    )
  }

  return WrappedComponent
}

export default LoginLayout
