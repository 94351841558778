/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Retour à la connexion',
    change: 'Changer',
    finish: 'Terminer',
    next: 'Suivant',
    ok: 'OK',
    submit: 'Soumettre',
  },
  carousel: {
    announcementLink: 'Cliquez ici pour plus d’informations',
    announcementText: 'Bonjour, nous avons introduit une nouvelle fonctionnalité',
  },
  emailSent: {
    checkEmail: 'Nous avons envoyé un e-mail à cette adresse avec des instructions de réinitialisation. Si vous ne la voyez pas dans une minute, veuillez vérifier votre dossier de courrier indésirable ou vérifier que l’adresse e-mail ci-dessus est correcte.',
    resentLink: 'Renvoyer le lien',
    subTitle: 'Vérifier votre boîte de réception',
  },
  expiredLink: {
    sentNewLink: 'Il semblerait que ce lien ait expiré. Nous en avons envoyé un nouveau à votre adresse e-mail.',
    title: 'Oups !',
  },
  lockedUser: {
    title: 'Le statut de votre compte est Désactivé, Verrouillé ou Non invité. Veuillez contacter votre administrateur ZenQMS pour obtenir de l’aide afin d’accéder à votre compte.',
  },
  loginMain: {
    checkID: 'L’identifiant ou le mot de passe que vous avez saisi est incorrect',
    forgotEmail: 'Vous ne vous souvenez pas de l’e-mail ou de l’identifiant ?',
    forgotPassword: 'Vous avez oublié votre mot de passe ?',
    invalidUsername: 'Le nom d’utilisateur que vous avez saisi n’est pas valide.',
    login: 'Se connecter',
    noAccount: 'Vous n’avez pas de compte ?',
  },
  needToConfirmPopup: {
    header: 'Veuillez contacter l’administrateur ZenQMS de votre société pour aider à vérifier l’identifiant de votre compte.',
  },
  newPassword: {
    containsNumber: '- Avoir au moins 1 chiffre',
    create: 'Créer un nouveau mot de passe',
    expiredPasswordAlert: 'Votre mot de passe a expiré et doit être réinitialisé.',
    hasNoRepeatCharacters: '- Ne pas inclure plus de 3 caractères répétés',
    isLengthValid: '- Avoir au moins {minPasswordLength} caractères',
    isMatch: '- Les mots de passe correspondent',
    notEmail: '- Ne pas être identique à celui de votre e-mail',
    notUsed: '- Ne pas répéter l’un de vos 12 derniers mots de passe',
    passwordMust: 'Votre mot de passe doit :',
    submit: 'Soumettre',
  },
  passwordResetSuccess: {
    subTitle: 'Cliquez sur « Retour à la connexion » ci-dessous pour vous connecter avec votre nouveau mot de passe.',
    title: 'Votre mot de passe a été réinitialisé !',
  },
  placeholders: {
    emailOrId: 'E-mail ou identifiant',
    newPassword: 'Nouveau mot de passe',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le nouveau mot de passe',
  },
  recoverPassword: {
    sendCode: 'Envoyer le code à mon appareil',
    sendLink: 'Envoyer un lien à mon e-mail',
    subTitle: 'Comment souhaitez-vous récupérer votre mot de passe ?',
    title: 'Récupération du mot de passe',
  },
  resetPassword: {
    email: 'E-mail',
    sendLink: 'Envoyer le lien',
    subTitle: 'Vérification requise',
  },
  setTwoFa: {
    code: 'Nous vous enverrons un code de sécurité à ce numéro chaque fois que vous vous connecterez à partir d’un appareil non fiable.',
    subTitle: 'Saisir votre numéro de téléphone portable',
    title: 'Configuration d’autorisation à deux facteurs',
    locked: 'Ce n’est pas correct. Votre compte a été verrouillé par mesure de sécurité.  Veuillez demander à votre administrateur de réinitialiser votre compte ou envoyez un e-mail à help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Accepter',
    and: ' et ',
    decline: 'Refuser',
    header: 'Conditions générales/Politique de confidentialité',
    message1: 'J’ai consulté les derniers ZenQMS et j’accepte de m’y conformer ',
    message2: ', y compris le consentement aux cookies de sessions temporaires requis pour le traitement des données et le stockage des informations dans nos serveurs sécurisés du monde entier.',
    privacy: 'Politique de confidentialité',
    terms: 'Conditions de service',
  },
  twofa: {
    code: 'Code de sécurité',
    noCode: 'Vous n’avez pas reçu de code ?',
    rememberDevice: 'Se souvenir de cet appareil pendant 30 jours',
    resend: 'Renvoyer',
    subTitle: 'Votre compte est protégé par l’authentification à deux facteurs (2FA)',
    title: 'Vérification de sécurité',
    verification: 'Un message texte contenant votre code a été envoyé à (***) ***-{number}',
    verify: 'Vérifier',
    wrongCode: 'Le code que vous avez saisi est incorrect, veuillez réessayer',
    verificationLimits: 'Vous avez dépassé la limite de vérification, veuillez réessayer dans 10 minutes.',
  },
  updateEmail: {
    alreadyExist: 'Cet e-mail est déjà utilisé',
    code: 'Nous vous enverrons un code de sécurité à ce numéro chaque fois que vous vous connecterez à partir d’un appareil non fiable.',
    title: 'Votre messagerie électronique a été limitée par vos paramètres d’administration. Veuillez saisir une nouvelle adresse e-mail pour votre compte.',
    success: 'Votre messagerie électronique a été mise à jour. Veuillez cliquer sur « Retour à la connexion » pour vous connecter à l’application.',
    wrongDomain: 'ce domaine d’e-mail n’est \'pas autorisé',
  },
  alert: {
    error: '[TRANSLATE]Error',
    success: '[TRANSLATE]Success',
    warning: 'Avertissement',
  },
  memberLocked: {
    title: 'Le compte de votre société a été verrouillé. Veuillez contacter votre administrateur ZenQMS ou billing@zenqms.com',
  },
  validations: {
    email: 'L’e-mail est un champ requis',
  },
}
