import { useCallback } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import { useField } from 'formik'

import 'react-phone-input-2/lib/style.css'

const PhoneNumberField = ({ name }) => {
  const id = `id-${name}`
  const [{ onChange, ...field }] = useField(name)

  const onValueChange = useCallback((phoneNumber, country, e) => { onChange(e) }, [onChange])

  return (
    <PhoneInput
      copyNumbersOnly
      country="us"
      disableSearchIcon
      enableSearch
      preferredCountries={['us', 'gb']}
      searchNotFound=""
      searchPlaceholder=""
      inputProps={{ name, id, onBlur: field.onBlur }}
      {...field}
      onChange={onValueChange}
    />
  )
}

PhoneNumberField.propTypes = {
  name: PropTypes.string.isRequired,
}

export default PhoneNumberField
