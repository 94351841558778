import { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'

const CheckboxField = ({ labelProps: { label, required }, inputProps, name }) => {
  const id = `id-${name}`

  const [{ value, ...field }, { initialValue }] = useField(name)
  const { setFieldValue } = useFormikContext()

  const handleOnChange = useCallback(
    e => {
      const status = e.target.checked

      setFieldValue(name, status === !!initialValue ? initialValue : status)
    },
    [name, setFieldValue, initialValue],
  )

  return (
    <div className="checkbox">
      <label htmlFor={id}>
        <input
          checked={!!value}
          id={id}
          name={name}
          {...field}
          onChange={handleOnChange}
          {...inputProps}
          type="checkbox"
        />
        {label && <span className={clsx({ required })}>{label}</span>}
      </label>
    </div>
  )
}

CheckboxField.defaultProps = {
  inputProps: {},
  labelProps: {},
}

CheckboxField.propTypes = {
  inputProps: PropTypes.shape({}),
  labelProps: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
}

export default CheckboxField
